import React from 'react';

import { TextLink } from '@sparky';

import { useChat } from './useChat';

interface Props {
  'data-chattopic': string;
  'data-displaytext': string;
  'data-senddisplaytext': string;
  children?: React.ReactNode;
}

const ChatLinkButton: React.FC<Props> = ({
  'data-chattopic': topic,
  'data-displaytext': askText,
  'data-senddisplaytext': sendTopicAndAskText,
  children,
}) => {
  const [mount] = useChat();
  return (
    <TextLink
      onClick={() =>
        mount({ visibility: 'open', topic, askText, sendTopicAndAskText: sendTopicAndAskText === 'true' })
      }>
      {children}
    </TextLink>
  );
};

export default ChatLinkButton;
